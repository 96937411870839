import React, { useState, useEffect, useCallback } from 'react';
import Header from '../../components/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SuccessNotification from '../../components/Notifications/SuccessNotification';
import ErrorNotification from '../../components/Notifications/ErrorNotification';
import LoginNotification from "../../components/Notifications/LoginNotification";
import { faCircleArrowLeft, faDollarSign, faCheck, faBasketShopping, faFileCircleCheck } from '@fortawesome/free-solid-svg-icons';
import Table from '../../components/Table';
import Section from '../../components/Section';
import apiClient from "../../axios";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Loader from "../../components/Loader";
import ApproveModal from "../../components/ApproveModal";

const PurchaseSummary = ({ handleLogout }) => {
    const { orderNumber } = useParams();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const itemsPerPage = 10;
    const [isSuccessVisible, setIsSuccessVisible] = useState(false);
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const navigate = useNavigate();
    const [isLoginVisible, setIsLoginVisible] = useState(false);
    const [orderData, setOrderData] = useState({
        numSerie: "",
        cliente: "",
        servidor: "",
        tipocontra: "",
        contEmpre: "",
        observacion: "",
        tipoContrato: "",
        distribuidor: "",
        status: "",
        clientName: ""
    });
    const [saleData, setSaleData] = useState({
        contractId: "",
        invoiceNumber: "",
        total: 0,
        subtotal: 0,
        taxPercentage: 0,
        taxes: 0,
        distributorName: "",
        userName: ""
    });
    const [modalVisible, setModalVisible] = useState(false);
    const role = localStorage.getItem('role');


    useEffect(() => {
        // Función para cargar datos de la orden
        const fetchOrderData = async () => {
            try {
                const response = await apiClient.get(`/orders/${orderNumber}`);
                const dataGeneral = response.data;
                const data = response.data.contractData;
                setOrderData({
                    numSerie: data.numSerie || '',
                    cliente: data.cliente || '',
                    servidor: data.servidor || '',
                    tipocontra: data.productType || '',
                    contEmpre: data.contEmpre || '',
                    observacion: data.observacion || '',
                    tipoContrato: data.contractType || '',
                    status: dataGeneral.status || '',
                    clientName: data.clientName || '',
                });
                const dataSale = response.data;
                setSaleData({
                    contractId: dataSale.contractId || '',
                    invoiceNumber: dataSale.invoiceNumber || '',
                    total: dataSale.total || 0,
                    subtotal: dataSale.subtotal || 0,
                    taxPercentage: dataSale.taxPercentage || 0,
                    taxes: dataSale.taxes || 0,
                    distributorName: dataSale.distributorName || '',
                    userName: dataSale.userName || '',
                });
            } catch (error) {
                console.error("Error al obtener los datos de la orden:", error);
            }
        };

        fetchOrderData();
    }, [orderNumber]); // Agregado orderNumber como dependencia

    const fetchAllData = useCallback(async (page = 1) => {
        try {
            setLoading(true);
            const response = await apiClient.get(`/orders/${orderNumber}`);
            const orderItems = response.data.orderItems;
            if (Array.isArray(orderItems)) {
                setData(orderItems);
                setTotalItems(orderItems.length);
            }
        } catch (error) {
            setIsErrorVisible(true);
            if (error.response && error.response.status === 401) {
                setIsLoginVisible(true);
            }
        } finally {
            setLoading(false);
        }
    }, [orderNumber]); // Agregado orderNumber como dependencia

    useEffect(() => {
        fetchAllData(currentPage);
    }, [currentPage, fetchAllData]); // Agregado fetchAllData como dependencia

    const handleRefresh = () => {
        fetchAllData(currentPage);
    };

    const handleStatusClick = () => {
        setModalVisible(true);
    };
    const handleCloseModal = () => {
        setModalVisible(false);
    };

    const handleDownload = async (event) => {
        event.preventDefault();
        try {
            setLoading(true);
            const response = await apiClient.get(`/orders/${orderNumber}/voucher`, {
                responseType: 'blob', // Importante para manejar la respuesta como blob
            });

            if (response.status === 200) {
                // Detectar el tipo de archivo desde el encabezado de Content-Type
                const contentType = response.headers['content-type'];
                let extension = 'pdf'; // Valor por defecto

                if (contentType === 'image/png') {
                    extension = 'png';
                } else if (contentType === 'image/jpeg') {
                    extension = 'jpg';
                }

                // Crear un objeto URL para el blob
                const url = window.URL.createObjectURL(new Blob([response.data]));

                // Crear un enlace temporal para descargar el archivo
                const a = document.createElement('a');
                a.href = url;
                a.download = `voucher.${extension}`; // Asigna la extensión correcta
                document.body.appendChild(a);
                a.click();
                a.remove();

                // Liberar el objeto URL
                window.URL.revokeObjectURL(url);

                setIsSuccessVisible(true);
            }

        } catch (error) {
            setIsErrorVisible(true);
            setIsSuccessVisible(false);
            setTimeout(() => {
                setIsErrorVisible(false);
            }, 4000);
            if (error.response && error.response.status === 401) {
                setIsLoginVisible(true);
            }
        } finally {
            setLoading(false);
        }
    };



    const columns = [
        { title: "Código", key: "code" },
        { title: "Descripción", key: "description" },
        { title: "Precio Modulo", key: "purchasePrice" },
        { title: "Usuarios", key: "subscriptions" },
        { title: "Usuarios Extras", key: "extraUsers" },
        { title: "Descuento", key: "dicount" },
        { title: "Total", key: "total" }
    ];

    const renderRow = (item, index) => {
        const subtotal = item.extraUsers * item.perUserPrice + (item.purchasePrice * item.users);
        const total = subtotal - (subtotal * (item.discount / 100));
        return (<>
            <td>{item.code}</td>
            <td>
                {item.users !== 0 ? (
                    <>
                        <div>{item.description} </div>
                    </>
                ) : (
                    <div>{item.perUserDescription}</div>
                )}
            </td>
            <td className="align-right">${item.purchasePrice.toFixed(2)}</td>
            <td className="align-center">{item.users}</td>
            <td className="align-center">
                {item.extraUsers}
                <div className="align-center">${item.perUserPrice.toFixed(2)} c/u</div>
            </td>
            <td className="align-right">{item.discount}%</td>
            <td className="align-right">${total.toFixed(2)}</td>
        </>)

    };

    return (
        <div className="home-container">
            <Header onLogout={handleLogout} title='Clientes' />
            <Section>
                <div className="button-return-container">
                    <FontAwesomeIcon
                        className="basic-shortcut-icon"
                        style={{ cursor: "pointer" }}
                        icon={faCircleArrowLeft}
                        onClick={(e) => {
                            e.stopPropagation();
                            navigate("/PurchaseList");
                        }}
                    />
                </div>
                <div>
                    <label>ORDEN Nº: {orderNumber} |  CLIENTE: {orderData.clientName} |  CANAL: {saleData.distributorName} |  USUARIO: {saleData.userName} </label>
                </div>
                <div className="button-add">
                    {((role === 'SUPER_ADMIN' && orderData.status === 'Pending') || (role === 'ADMIN' && orderData.status === 'Pending')) && (
                        <button
                            className="basic-custom-button"
                            onClick={() => handleStatusClick()}
                        >
                            <FontAwesomeIcon icon={faFileCircleCheck} className="basic-shortcut-icon" />
                            APROBAR
                        </button>
                    )}
                </div>
            </Section>
            <div className="moduleNew-form-container">
                <h3 className="basic-info-form-title">Resumen de la Orden</h3>
                <div className="basic-info-form-grid">
                    {saleData.contractId && (
                        <div className="basic-info-form-group">
                            <label> Nro. Contrato </label>
                            <input
                                type="text"
                                placeholder=" Nro. Contrato"
                                value={saleData.contractId}
                                readOnly
                            />
                        </div>
                    )}
                    {saleData.invoiceNumber && (
                        <div className="basic-info-form-group">
                            <label> Nro. Factura </label>
                            <input
                                type="text"
                                placeholder=" Nro. Factura"
                                value={saleData.invoiceNumber}
                                readOnly
                            />
                        </div>
                    )}
                    <div className="basic-info-form-group">
                        <label>Cliente</label>
                        <input
                            type="text"
                            placeholder="Cliente"
                            value={orderData.cliente}
                            readOnly
                        />
                    </div>
                    <div className="basic-info-form-group">
                        <label>Servidor</label>
                        <input
                            type="text"
                            placeholder="Servidor"
                            value={orderData.servidor}
                            readOnly
                        />
                    </div>
                    <div className="basic-info-form-group">
                        <label> Nro. Identificador</label>
                        <input
                            type="text"
                            placeholder=" Nro. Identificador"
                            value={orderData.numSerie}
                            readOnly
                        />
                    </div>
                    <div className="basic-info-form-group">
                        <label> Producto</label>
                        <input
                            type="text"
                            placeholder="Producto"
                            value={orderData.tipocontra}
                            readOnly
                        />
                    </div>
                    <div className="basic-info-form-group">
                        <label>Tipo Contrato</label>
                        <input
                            type="text"
                            placeholder="Tipo Contrato"
                            value={orderData.tipoContrato}
                            readOnly
                        />
                    </div>
                    <div className="basic-info-form-group">
                        <label>Observación</label>
                        <input
                            type="text"
                            placeholder="Observación"
                            value={orderData.observacion}
                            readOnly
                        />
                    </div>
                    <div className="basic-info-form-group">
                        <label>Descripción</label>
                        <input
                            type="text"
                            placeholder="Descripción"
                            value={orderData.contEmpre}
                            readOnly
                        />
                    </div>
                    {orderData.tipoContrato === "Contador" && (
                        <div className="basic-info-form-group">
                            <label htmlFor="file">Descargar Factura</label>
                            <button className="basic-custom-button" onClick={handleDownload}>
                                Factura
                            </button>
                        </div>
                    )}
                </div>
                <div className="basic-info-form-group">
                    <Table
                        title="Lista de Productos"
                        rows={data}
                        columns={columns}
                        icon={faBasketShopping}
                        renderRow={renderRow}
                        currentPage={currentPage}
                        totalItems={totalItems}
                        itemsPerPage={itemsPerPage}
                        onPageChange={setCurrentPage}
                        onRefresh={handleRefresh}
                        loading={loading}
                        flagPagination={false}
                    />
                </div>
                <div className="basic-form-footer">
                    <div className="basic-footer-container-group ">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <FontAwesomeIcon icon={faDollarSign} className="basic-shortcut-icon" />
                            <h3 className="modal-title">Resumen</h3>
                        </div>
                        <table style={{ borderCollapse: 'collapse', width: '30%' }}>
                            <tbody>
                                <tr>
                                    <td style={{ padding: '10px', textAlign: 'left', borderBottom: '1px solid black' }}>
                                        <label>Subtotal:</label>
                                    </td>
                                    <td style={{ padding: '10px', textAlign: 'right', borderBottom: '1px solid black' }}>
                                        <span>${saleData.subtotal.toFixed(2)}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '10px', textAlign: 'left', borderBottom: '1px solid black' }}>
                                        <label>Porcentaje IVA:</label>
                                    </td>
                                    <td style={{ padding: '10px', textAlign: 'right', borderBottom: '1px solid black' }}>
                                        <span>{saleData.taxPercentage.toFixed(2)}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '10px', textAlign: 'left', borderBottom: '1px solid black' }}>
                                        <label>Valor IVA:</label>
                                    </td>
                                    <td style={{ padding: '10px', textAlign: 'right', borderBottom: '1px solid black' }}>
                                        <span>${saleData.taxes.toFixed(2)}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '10px', textAlign: 'left', borderBottom: '1px solid black' }}>
                                        <label>Total:</label>
                                    </td>
                                    <td style={{ padding: '10px', textAlign: 'right', borderBottom: '1px solid black' }}>
                                        <span>${saleData.total.toFixed(2)}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="basic-form-footer">
                    <button
                        className="basic-custom-button"
                        onClick={(e) => {
                            e.stopPropagation();
                            navigate(-1);
                        }}
                    >
                        <FontAwesomeIcon icon={faCheck} className="basic-shortcut-icon" />
                        OK
                    </button>
                </div>
            </div>
            {loading && <Loader />}
            {isSuccessVisible && (
                <SuccessNotification
                    message="La factura se descargó con éxito."
                    onClose={() => setIsSuccessVisible(false)}
                />
            )}
            {isErrorVisible && (
                <ErrorNotification
                    message="Error al realizar la compra."
                    onClose={() => setIsErrorVisible(false)}
                />
            )}
            {isLoginVisible && (
                <LoginNotification
                    message="La sesión ha expirado."
                    onClose={() => setIsLoginVisible(false)}
                />
            )}
            {modalVisible && (
                <ApproveModal
                    message={"¿Desea Aprobar la Orden?"}
                    isVisible={modalVisible}
                    onClose={handleCloseModal}
                    id={orderNumber}
                />
            )}
        </div>
    );
};

export default PurchaseSummary;
